@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-autocomplete {
  --padding-autocomplete: 0.5em 1.5em;
  --max-height-autocomplete: 200px;
  --border-autocomplete: 1px solid #{colors.$clr-white};
  --bg-clr-autocomplete: #{colors.$clr-black};
  --bg-clr-autocomplete-prediction-hover: #1c1c1c;
  --width-icons-autocomplete: 15px;
  --height-icons-autocomplete: 15px;

  --gap-autocomplete: 1em;

  --ff-autocomplete: inherit;
  --fs-autocomplete: #{typography.$fs-500};
  --fw-autocomplete: inherit;
  --line-height-autocomplete: inherit;
  --clr-text-autocomplete: #{colors.$clr-white};

  --border-radius-autocomplete: 0;
  --opacity-placeholder-autocomplete: 1;
  --clr-thumb-scrollbar-autocomplete: #{colors.$clr-white};
  --clr-track-scrollbar-autocomplete: transparent;
}

.rt-autocomplete.rt-autocomplete.rt-autocomplete.rt-autocomplete {
  // * border radii
  .rt-input-container {
    border-radius: var(--border-radius-autocomplete);
  }

  .rt-predictions {
    border-bottom-left-radius: var(--border-radius-autocomplete);
    border-bottom-right-radius: var(--border-radius-autocomplete);
  }

  &--open {
    .rt-input-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // * borders
  .rt-input-container,
  .rt-predictions {
    border: var(--border-autocomplete);
  }

  &--open {
    .rt-input-container {
      border-bottom: none;
    }

    .rt-predictions {
      border-top: none;
    }
  }

  // * background
  .rt-input-container,
  input,
  .rt-icon,
  .rt-predictions {
    background-color: var(--bg-clr-autocomplete);
  }

  // * padding
  .rt-input-container,
  .rt-prediction {
    padding: var(--padding-autocomplete);
  }

  // * gap
  .rt-input-container {
    gap: var(--gap-autocomplete);
  }

  // * typography
  li,
  input,
  ::placeholder {
    font-family: var(--ff-autocomplete);
    font-size: var(--fs-autocomplete);
    font-weight: var(--fw-autocomplete);
    color: var(--clr-text-autocomplete);
    line-height: var(--line-height-autocomplete);
  }

  // * scrollbar
  .rt-predictions {
    max-height: var(--max-height-autocomplete);
    @include mixins.scrollbar(
      var(--clr-thumb-scrollbar-autocomplete),
      var(--clr-track-scrollbar-autocomplete),
      4px
    );
  }

  // * active states
  .rt-prediction:hover {
    background-color: var(--bg-clr-autocomplete-prediction-hover);
  }

  // * icons
  .rt-icon img {
    width: var(--width-icons-autocomplete);
    height: var(--height-icons-autocomplete);
  }

  // * misc
  ::placeholder {
    opacity: var(--opacity-placeholder-autocomplete);
  }
}
