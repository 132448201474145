@font-face {
  font-family: Karla;
  src: url("/font/karla/Karla-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: Karla;
  src: url("/font/karla/Karla-Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: Karla;
  src: url("/font/karla/Karla-Bold.woff2") format("woff2");
  font-weight: 700;
}
