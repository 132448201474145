@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-toast-container.rt-toast-container.rt-toast-container {
  --clr-progress-bar-left: #d6d6d6;
  --clr-progress-bar-right: hsla(0, 0%, 39%, 0.7);
  --bg-clr-toast: #{colors.$clr-white};
  --padding-toast: 1em;
  --close-icon-toast-url: revert;
  --close-icon-toast-size: 10px;
  --close-icon-toast-position: top 10px right 10px;

  --fs-toast: 1rem;
  --fw-toast: 700;
  --clr-message: #{colors.$clr-black};

  --box-shadow-toast: 0 0 10px black;
}

.rt-toast-container.rt-toast-container.rt-toast-container {
  background-color: var(--bg-clr-toast);
  background-image: var(--close-icon-toast-url);
  background-size: var(--close-icon-toast-size);
  background-position: var(--close-icon-toast-position);

  font-size: var(--fs-toast);
  font-weight: var(--fw-toast);
  color: var(--clr-message);

  box-shadow: var(--box-shadow-toast);

  p {
    padding: var(--padding-toast);
  }
}
