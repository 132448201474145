// https://andy-bell.co.uk/a-modern-css-reset/

:root {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.rt-homepage,
.rt-concierge,
.rt-sitemap {
  line-height: 1.4;

  /* Box sizing rules and font inheriting */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Remove list styles on ul and ol elements */
  ul,
  ol {
    list-style: none;
  }

  /* Remove text decoration from a elements */
  a {
    text-decoration: none;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;

    width: revert;
    height: revert;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}
