$ff-primary: Karla, sans-serif;

$fs-100: 0.565rem; // 9px
$fs-150: 0.6875rem; // 11px
$fs-200: 0.75rem; // 12px
$fs-350: 0.8125rem; // 13px
$fs-400: 0.875rem; // 14px
$fs-450: 0.9375rem; // 15px
$fs-500: 1rem; // 16px
$fs-600: 1.0625rem; // 17px
$fs-700: 1.1875rem; // 19px
$fs-730: 1.3125rem; // 21px
$fs-750: 1.375rem; // 22px
$fs-770: 23px; // 23px
$fs-780: 1.6875rem; // 27px
$fs-800: 1.875rem; // 30px
$fs-850: 35px; // 35px
$fs-900: 2.3125rem; // 37px
$fs-950: 2.8125rem; // 45px

$fw-400: 400;
$fw-500: 500;
$fw-700: 700;
