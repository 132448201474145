@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-store {
  --bg-clr-store-card: white;
  --padding-store-card: 0;

  // store name
  --fs-store-card-name: #{typography.$fs-500};
  --fw-store-card-name: #{typography.$fw-700};

  // store info
  --gap-store-card-info: 0.35em;
  --fs-store-card-info: inherit;
  --fw-store-card-info: inherit;

  // store cta
  --gap-store-card-cta: 0.75em;
  --min-width-store-card-cta-btn: 200px;
  --fs-store-card-cta: #{typography.$fs-500};
  --fw-store-card-cta: #{typography.$fw-500};
}

.rt-store article {
  display: flex;
  flex-direction: column;

  background-color: var(--bg-clr-store-card);
  padding: var(--padding-store-card);
}

.rt-store__name {
  margin-bottom: 0.25em;

  font-size: var(--fs-store-card-name);
  font-weight: var(--fw-store-card-name);
}

.rt-store__info {
  display: flex;
  flex-direction: column;
  gap: var(--gap-store-card-info);

  font-size: var(--fs-store-card-info);
  font-weight: var(--fw-store-card-info);

  margin-bottom: 1.5em;
}

.rt-opening-time {
  font-weight: typography.$fw-700;
}

.rt-store__cta {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-store-card-cta);

  margin-top: auto;

  font-size: var(--fs-store-card-cta);
  font-weight: var(--fw-store-card---fs-store-card-cta);

  > * {
    flex: 1;
    min-width: var(--min-width-store-card-cta-btn);
  }
}
