@use "breakpoints";
// @use "sass:string";

// * Breakpoints *

@mixin desktop-up {
  @media (min-width: breakpoints.$desktop) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: #{breakpoints.$desktop - 0.1}) {
    @content;
  }
}

@mixin medium-desktop-up {
  @media (min-width: breakpoints.$medium-desktop) {
    @content;
  }
}

@mixin large-desktop-up {
  @media (min-width: breakpoints.$large-desktop) {
    @content;
  }
}

// * Utilities *

// Mixin that inserts a ::before on the element, and takes a width, a height and a url for the image that will
// be treated as a background-image of that ::before
@mixin pseudo(
  $width,
  $height,
  $iconUrl,
  $pseudoElType: "::before",
  $display: "inline-block"
) {
  &#{$pseudoElType} {
    content: "";

    display: #{$display};

    flex-shrink: 0;

    width: $width;
    height: $height;

    background-image: url($iconUrl);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// Mixin that makes the element transition to a new opacity value whenever hover occurs
@mixin opacity-fade-on-hover(
  $transition: 250ms ease-out opacity,
  $opacity: 0.75
) {
  transition: $transition;

  &:hover {
    opacity: $opacity;
  }
}

// Mixin that styles a scrollbar for the element the mixin is applied and all of its children
@mixin scrollbar(
  $clr-scrollbar-thumb,
  $clr-scrollbar-track,
  $scrollbar-width: 4px
) {
  // * webkit
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: $clr-scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $clr-scrollbar-thumb;
  }

  &::-webkit-scrollbar:horizontal {
    height: $scrollbar-width;
  }

  // * firefox
  & {
    scrollbar-color: $clr-scrollbar-thumb $clr-scrollbar-track;
    scrollbar-width: thin;
  }
}
