@use "../abstracts/typography";
@use "../abstracts/colors";
@use "../abstracts/mixins";

// * grid-related stuff
.rt-header {
  .rt-main-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
      "logo nav"
      "geo geo";
    gap: 1.5em;

    @include mixins.large-desktop-up {
      grid-template-columns: 1fr 3fr 1fr;
      grid-template-areas: "logo geo nav";
      align-items: center;
    }
  }
}

.rt-logo {
  grid-area: logo;
}

.rt-main-navigation {
  grid-area: nav;
  justify-self: end;
  align-self: start;

  @include mixins.desktop-up {
    align-self: center;
  }
}

.rt-geo-controls {
  grid-area: geo;

  > * {
    width: 100%;
  }
}

// * general stuff
.rt-header {
  --padding-top-header: 1em;
  --padding-bottom-header: 2em;

  background-color: colors.$clr-black;
  padding-top: var(--padding-top-header);
  padding-bottom: var(--padding-bottom-header);

  color: colors.$clr-white;

  @include mixins.desktop-up {
    --padding-top-header: 2em;
  }
}

.rt-geo-controls {
  font-size: typography.$fs-450;
  --padding-geo-controls: 0.6em 1.5em;
  // --border-color-geo-controls: #{colors.$clr-gray-500};
}

// * logo
.rt-logo {
  > * {
    margin-top: 0.25em;

    font-size: 15px;
    font-weight: typography.$fw-400;
    text-transform: uppercase;

    @include mixins.desktop-up {
      font-size: 16px;
    }
  }

  img {
    width: 121px;

    @include mixins.desktop-up {
      width: 180px;
    }
  }
}

// * geo controls
.rt-geo-controls {
  display: grid;
  gap: 1em;

  @include mixins.desktop-up {
    grid-template-columns: repeat(3, minmax(260px, 1fr));
  }

  > * {
    min-height: 40px;
  }
}

// * main navigation
.rt-main-navigation {
  display: flex;
  align-items: center;
  gap: 32px;

  text-align: center;
  text-transform: capitalize;

  &__language {
    text-transform: uppercase;
  }

  @include mixins.desktop-up {
    font-size: typography.$fs-500;
    gap: 40px;
  }
}

// ! Adjustments for concierge/sitemap
.rt-concierge,
.rt-sitemap {
  .rt-header .rt-main-container {
    --main-container-max-width: 1800px;
    --main-container-relative-width: 95%;

    grid-template-columns: auto auto;
    grid-template-areas:
      "logo nav"
      "geo  geo";
    row-gap: 0;
  }
}
